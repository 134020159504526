
import {Component, Vue} from "vue-property-decorator";
import LogInfo from "@/components/infos/LogInfo.vue";

@Component({
  components:{LogInfo}
})
export default class LogView extends Vue{

}
