export enum AuditableType {
    'App\\Models\\IcraPro\\Alacak'= 'App\Models\IcraPro\Alacak',
    'App\\Models\\IcraPro\\AlacakKalemi'= 'App\Models\IcraPro\AlacakKalemi',
    'App\\Models\\IcraPro\\Alacakli'= 'App\Models\IcraPro\Alacakli',
    'App\\Models\\IcraPro\\Borclu'= 'App\Models\IcraPro\Borclu',
    'App\\Models\\IcraPro\\Takip'= 'App\Models\IcraPro\Takip',
    'App\\Models\\Kisi'= 'App\Models\Kisi'
}

export enum AuditableTypeLabel {
    'App\\Models\\IcraPro\\Alacak' = 'Alacak',
    'App\\Models\\IcraPro\\AlacakKalemi' = 'Alacak Kalemi',
    'App\\Models\\IcraPro\\Alacakli' = 'Alacaklı',
    'App\\Models\\IcraPro\\Borclu' = 'Borçlu',
    'App\\Models\\IcraPro\\Takip' = 'Takip',
    'App\\Models\\Kisi' = 'Kişi'
}