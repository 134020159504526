
import {Component, Vue, Watch} from "vue-property-decorator";
import {LogEntity} from "@/entity/LogEntity";
import {LogEvent, LogEventLabel} from "@/enum/LogEvent";
import {AuditableTypeLabel} from "@/enum/AuditableType";
import {TakipEntity} from "@/entity/TakipEntity";
import Dates from "@/components/inputs/Dates.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

interface Options {
  sortBy: string[],
  sortDesc: boolean[],
  page: number,
  itemsPerPage: number
}

@Component({
  components: {SayfaAciklama, PersonelPicker, EnumPicker, Dates}
})
export default class LogInfo extends Vue {
  logEvent = LogEvent;
  logEventLabel = LogEventLabel;
  auditableTypeLabel = AuditableTypeLabel;
  headers = [
    {text: "#", value: "id", width: 50},
    {text: "Tarih", value: "tarih", width: 170},
    {text: "Etkinlik", value: "event", width: 90},
    {text: "Personel", value: "isim", width: 170},
    {text: "IP", value: "ip_address"},
    {text: "Kategori", value: "kategori"},
    {text: "Eski Değer", value: "old_values"},
    {text: "Yeni Değer", value: "new_values"}
  ]
  items: Array<LogEntity> = [];

  filterLineVisible = false;
  itemsLenght = 0;
  isLoading = true;
  options: Options = { sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10 };
  filters: any = {
    baslangicTarihi: null,
    bitisTarihi: null,
    event: null,
    user_id: null
  };
  oldFilters = {};
  oldOptions = {};

  mounted() {
    this.load();
  }

  @Watch('options')
  load() {
    let filtre: any = {}
    Object.keys(this.filters).forEach(key => (this.filters[key] != null && this.filters[key] != '') ? (filtre[key] = this.filters[key]) : null)
    if (JSON.stringify(filtre) != JSON.stringify(this.oldFilters))
      this.options.page = 1;
    const {sortBy, sortDesc, page, itemsPerPage} = this.options;
    if (JSON.stringify(filtre) != JSON.stringify(this.oldFilters) || JSON.stringify(this.options) != JSON.stringify(this.oldOptions)) {
      this.oldFilters = {...filtre};
      this.oldOptions = {...this.options};
      this.isLoading = true;
      this.$http.get('/api/v1/log/filter', {
        params: {
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          page: page,
          itemsPerPage: itemsPerPage,
          ...filtre
        }
      }).then((response) => {
        this.items = response.data;
        // @ts-ignore
        this.itemsLenght = response.total;
        this.isLoading = false;
      })
    }
  }
}
