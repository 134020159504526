export enum LogEvent {
    created = 'created',
    updated = 'updated',
    deleted = 'deleted'
}

export enum LogEventLabel {
    created = 'Oluşturuldu',
    updated = 'Güncellendi',
    deleted = 'Silindi'
}
